<template>
	<v-dialog v-model="show_bid_box" persistent height="auto" width="640" transition="slide-fade" hide-overlay light class="map-box">
		<v-row no-gutters class="map-header d-flex px-8">
			<v-col cols="12" sm="6" class="d-flex align-center order-sm-1 order-2">
				<img v-if="value.category_id === 1" :src="$store.state.icons.icons['Lumber']" style="height: 36px" alt />
				<img v-if="value.category_id === 2" :src="$store.state.icons.icons['Electric']" style="height: 36px" alt />
				<img v-if="value.category_id === 3" :src="$store.state.icons.icons['Plumbing']" style="height: 36px" alt />
				<img v-if="value.category_id === 4" :src="$store.state.icons.icons['Masonry']" style="height: 36px" alt />
				<img v-if="value.category_id === 5" :src="$store.state.icons.icons['ToolBoxes']" style="height: 36px" alt />
				<img v-if="value.category_id === 6" :src="$store.state.icons.icons['Landscape']" style="height: 36px" alt />

				<div class="pl-7">
					<p class="heading-mf15">{{ value.list_name }}</p>
					<p class="heading-rf13">List ref: {{ value.list_id }}</p>
				</div>
			</v-col>
			<v-col cols="12" sm="6" class="d-sm-flex align-center justify-end order-sm-2 order-1 my-sm-0 my-3">
				<div v-on:click="show_bid_box = false" class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2">X Close map</div>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<div class="map-view" height="78vh">
				<v-img max-height="80vh" eager max-width="1280" height="78vh" alt :lazy-src="getSrc" :src="getSrc"></v-img>
			</div>
		</v-row>
	</v-dialog>
</template>

<script>
export default {
	props: {
		value: Object,
	},

	data() {
		return {
			show_bid_box: false,
			url: '',
		}
	},

	computed: {
		getSrc() {
			return this.url
		},
	},

	methods: {
		async openMap() {
			this.show_bid_box = true
			await this.getMapSrc()
		},

		getMapSrc() {
			this.url =
				this.value.url != undefined ? this.value.url['list_location'] : 'https://the-build-static-content.s3.us-east-2.amazonaws.com/icons/map-view.png'
		},
	},
}
</script>

<style lang="scss" scoped>
.map-header {
	z-index: 9;
	content: '';
	height: 80px;
	background: #ffffff;

	.text-tag-btn {
		height: 32px;
		width: 127px;
		cursor: pointer;
		font-family: Inter-Medium;
		font-size: 15px;
		color: #216fff;
	}
}

.map-view {
	overflow: hidden;
	/*height: 80vh;*/
}
@media only screen and (max-width: 600px) {
	.map-header {
		height: 100px;
	}
	.map-box {
		overflow: hidden;
	}
}
</style>
